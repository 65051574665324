import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {
    Box,
    Container,
    Button, Card, CardHeader, Divider, CardContent, CircularProgress, TextField, Alert, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, ButtonBase, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useParams, useNavigate} from 'react-router-dom';
import {useGet, usePut, usePost, useDelete} from "../../API/request";
import '../../styles/Avatar/style.css'
import { baseFetch } from '../../shared/api/fetch/main-fetch';
import { Loader } from '../../components/loader/Loader';
import { DropDownSelect } from '../../components/drop-down-select/DropDownSelect'
import { formatDate } from '../../shared/service/formattedDate';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'; 
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {en} from "dayjs/locale/en";

const CompanyEdit = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const getU = useGet();
    const putU = usePut();
    const postU = usePost();
    const deleteU = useDelete();

    const [isLoaded, setIsLoaded] = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [nothingChanged, setNothingChanged] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [uploadedImg, setUploadedImg] = useState('/static/images/defphoto.jpg');

    const [date, setDate] = React.useState(new Date())

    const handleChangeDate = (newValue) => {
      setDate(newValue)
    }
    
    const [values, setValues] = useState({
        userId: '',
        name: '',
        company_url: '',
        categories: '',
        delivery_schedule: '',
        is_enabled: false,
    });
    const [errors, setErrors] = useState({
        userId: false,
        name: false,
        company_url: false,
        categories: false,
        campaign_start_date: false,
        delivery_schedule: false,
    });
    const [roles, setRoles] = useState([])


    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

 
    


    useEffect(() => {
        setIsLoaded(true)
        getU(`company/all`)
            .then((resp) => {
                if (resp.status === 'success') {
                    const currentCompany = resp.data.companies.filter(company => company.company_id === Number(id))

                    const company = currentCompany[0]
                    
                    const companyDate = new Date(company.campaign_start_date)
                    setDate(companyDate)

                    setValues(prev => ({
                        ...prev,
                        name: company.name ?? '',
                        company_url: company.company_url ?? '',
                        categories: company.categories ?? '',
                        delivery_schedule: company.delivery_schedule ?? '',
                        is_enabled: company.is_enabled === 1 ? true : false ,
                    }))
                    
                }
            })
            .catch(() => {

            })
            .finally(() => {
                setIsLoaded(false)
            });
    }, []);



    const handleDeleteCompany = () => {

        deleteU(`company/delete/${id}`)
        .then(resp => {
            if (resp.status === 'success') {
                showAlert('success', 'Company delete');
                navigate('/companys')
            } else {
                showAlert('error', 'Error');
            }
        })
        .catch((err) => {
            showAlert('error', 'Error');
        })
        .finally(() => {
            setSubmitDisabled(false);
        })


    }
    
    const handleUpdateCompany = async() => {

        const formattedDate = formatDate(date)

        const data = {
                name: values.name,
                company_url: values.company_url,
                categories: values.categories,
                campaign_start_date: formattedDate,
                delivery_schedule: values.delivery_schedule,
                is_enabled: values.is_enabled,  
        }

         baseFetch({ url: `company/updateSettings/${id}`, payload: data, method: 'PATCH' } )
        .then(resp => {
            if (resp.status === 'success') {
                showAlert('success', 'Company update'); 
            } else {
                showAlert('error', 'Failed update company'); 
            }   
        })
        .catch((err) => {
            showAlert('error', 'Failed update company');
        })
        .finally(() => {
            setSubmitDisabled(false);
        })
    }

    const handleOpenModal = () => {
        setIsModalOpen(true);
      };
   
    const handleChangeCheckBox = () => {
        setNothingChanged(false);

        setValues(prev => ({
            ...prev,
            is_enabled: !prev.is_enabled,
        }));
    };

    const handleChange = (event) => {
        setNothingChanged(false);

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };


    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 2500);
    };

    const validateInfo = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (!values.userId) {
            validComplete = false;
            formErrors.userId = false;
            showAlert('error', "The User Id field should not be empty")

        }

        setErrors(formErrors);
        return validComplete;
    };

    const clearForm = () => {
        setValues({
            userId: '',
        });
    };
 

    const submitAddUser = async () => {
        if (nothingChanged) {
            showAlert('error', 'No changes');
            return;
        }

        if (validateInfo()) {
            setSubmitDisabled(true);

            const data = {
                id: values.userId,
                company_id: id,
            };
      
            postU(`clients/select-company`, data)
            .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'User successfully added');
                        clearForm()
                    } else {
                        showAlert('error', 'Ошибка');
                    }
                })
                .catch((err) => {
                    showAlert('error', 'Error the user with this id was not found');
                })
                .finally(() => {
                    setSubmitDisabled(false);
                })
            ;
        }
    };


    if (isLoaded) {
        return  <Loader/>;
    }

    const scheduleVariant = ['Weekly', 'Bi-Weekly']

    return (
        <>

<Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>Delete company</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Are you sure you want to delete the company ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCompany} color="error">
            Delete
          </Button>
          <Button onClick={() => setIsModalOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

            <Helmet>
                <title>Edit active clients</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                    Active clients
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Active clients</li>
                    <li>/</li>
                    <li>Edit</li>
                </ul>
            </Box>






            {/*image*/}
            <Box sx={{backgroundColor: 'background.default', pt: 3}}>

                <Container maxWidth={false}>
                    <Box sx={{mb: 1}}>
                        <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                            {alert.txt}
                        </Alert>
                    </Box>
                    <Box sx={{
                        paddingBottom: 1
                    }}>
                        
                    </Box>
                </Container>
            </Box>

            {/*Change info*/}
            <Box sx={{backgroundColor: 'background.default', pt: 3}}>

                <Container maxWidth={false}>
                    <Box sx={{
                        paddingBottom: 1
                    }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Change company info"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                    <TextField
                                        fullWidth
                                        label="Name"
                                        margin="normal"
                                        name="name"
                                        onChange={handleChange}
                                        type="string"
                                        value={values.name}
                                        variant="outlined"
                                        error={errors.name ? true : false}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Url"
                                        margin="normal"
                                        name="company_url"
                                        onChange={handleChange}
                                        type="string"
                                        value={values.company_url}
                                        variant="outlined"
                                        error={errors.company_url ? true : false}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Categories"
                                        margin="normal"
                                        name="categories"
                                        onChange={handleChange}
                                        type="string"
                                        value={values.categories}
                                        variant="outlined"
                                        error={errors.categories ? true : false}
                                    />
                                    <DropDownSelect
                                      showReset={false} 
                                      value={values.delivery_schedule}
                                      onChange={handleChange}
                                      seleckList={scheduleVariant}
                                      label='Shedule'
                                      name='delivery_schedule'
                                    /> 
                                    <Box sx={{pt: 2}}>
                                    <LocalizationProvider locale={en} dateAdapter={AdapterDateFns}>
<Box sx={{pt: 2}}>
      <DatePicker
        sx={{ width: '100%', margin: 20 }}
        label="Start date"
        inputFormat="dd-MMMM-yyyy"
        value={date}
        onChange={handleChangeDate}
        renderInput={(params) => <TextField {...params} />}
      />
</Box>
    </LocalizationProvider>
</Box>
   <Box sx={{pt: 2}}>

                                     <FormControlLabel 
                                     control={<Checkbox 
                                     checked={values.is_enabled}
                                     name="is_enabled"
                                     value={values.is_enabled} 
                                     onChange={handleChangeCheckBox} 
                                     />} label="Is Active" />
   </Box>
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={handleUpdateCompany}
                                        disabled={submitDisabled}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>


            {/*info*/}
            <Box sx={{backgroundColor: 'background.default', pt: 3}}>

                <Container maxWidth={false}>
                    <Box sx={{
                        paddingBottom: 1
                    }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Add a user to the company"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                    <TextField
                                        fullWidth
                                        label="User id"
                                        margin="normal"
                                        name="userId"
                                        onChange={handleChange}
                                        type="number"
                                        value={values.userId}
                                        variant="outlined"
                                        error={errors.userId}
                                    />
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submitAddUser}
                                        disabled={submitDisabled}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>

            <Box sx={{backgroundColor: 'background.default', pt: 3}}>

                <Container maxWidth={false}>
                    <Box sx={{
                        paddingBottom: 1
                    }}>

{/* ________________________________________________________________________________DELETE___________________________________________ */}                
                        {/* <form >
                            <Card color='red' sx={{ borderColor: 'red' }} styles={{borderColor: 'red'}}>
                                <CardHeader sx={{  borderColor: 'error' }}
                                    title="Danger Zone"
                                    color='red'
                                />
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2, borderColor: 'red'}}>
                                    <Button
                                        color="error"
                                        variant="contained"
                                        onClick={handleOpenModal}
                                        disabled={submitDisabled}
                                    >
                                        Delete this company
                                    </Button>
                                </Box>
                            </Card>
                        </form> */}
{/* ________________________________________________________________________________DELETE___________________________________________ */}

                    </Box>
                </Container>
            </Box>

        </>
    );
};

export default CompanyEdit;
