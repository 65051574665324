import {Helmet} from 'react-helmet';
import {
    Box,
    Container,
    Button, TextField, CardContent, FormControlLabel, Checkbox
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useNavigate} from 'react-router-dom';
import {useGet, usePost, usePut} from "../../API/request";
import React, {useEffect, useState} from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Alert from "@material-ui/core/Alert";
import { Loader } from '../../components/loader/Loader';
import { DropDownSelect } from '../../components/drop-down-select/DropDownSelect';
import { formatDate } from '../../shared/service/formattedDate';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'; 
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {en} from "dayjs/locale/en";

const CompanyAdd = () => {
    const scheduleVariant = ['Weekly', 'Bi-Weekly']
    const navigate = useNavigate();
    const postU = usePost();
    const getU = useGet();
    const putU = usePut();

    const [nothingChanged, setNothingChanged] = useState(true);
    const [values, setValues] = useState({
        name: '',
        company_url: '',
        categories: '',
        delivery_schedule: scheduleVariant[0],
        is_enabled: false,
    });
    const [errors, setErrors] = useState({
        name: '',
        company_url: '',
        categories: '',
        delivery_schedule: '',
    });


    const [isShowLoader, setIsShowLoader] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const [date, setDate] = React.useState(new Date())

  const handleChangeDate = (newValue) => {
    setDate(newValue)
  }

    const handleChange = (event) => {
        setNothingChanged(false);
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 1400);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};
        if (values.name === '') {
            validComplete = false;
            formErrors.name = false;
            showAlert('error', "The Company name field should not be empty")
        }

        setErrors(formErrors);
        return validComplete;
    };



    const clearForm = () => {
        setValues({
        name: '',
        company_url: '',
        categories: '',
        delivery_schedule: scheduleVariant[0],
        is_enabled: false,
        });
        setDate(new Date())
    };

    const submit = async () => {
        if (validate()) {
            setIsShowLoader(true);
            setSubmitDisabled(true);

            const formattedDate = formatDate(date)

            const data = {
                name: values.name,
                company_url: values.company_url,
                categories: values.categories,
                campaign_start_date: formattedDate,
                delivery_schedule: values.delivery_schedule,
                is_enabled: values.is_enabled,
            };

            postU('company/create', data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Company added');
                        clearForm();
                    } else {
                        showAlert('error', 'Failed to create company');
                    }
                })
                .catch((err) => {
                    showAlert('error', 'Server Error');
                    setIsShowLoader(false);
                    setSubmitDisabled(false);
                })
                .finally(()=>{
                    setIsShowLoader(false);
                    setSubmitDisabled(false);
                });
        }
    };

    const handleChangeCheckBox = () => {
        setNothingChanged(false);

        setValues(prev => ({
            ...prev,
            is_enabled: !prev.is_enabled,
        }));
    };
    
    if (isShowLoader) {
        return  <Loader/>;
    }



    return (
        <>
            <Helmet>
                <title>Create new company</title>
            </Helmet>
            <Box sx={{pt: 2}}>
                <Container maxWidth={false}>
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Container>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%'}}>
                <Container maxWidth={false}>
                    <Box sx={{pt: 2}}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Adding a new company"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                     <TextField
                                        fullWidth
                                        label="Company name"
                                        margin="normal"
                                        name="name"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.name}
                                        variant="outlined"
                                        error={errors.name ? true : false}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Url"
                                        margin="normal"
                                        name="company_url"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.company_url}
                                        variant="outlined"
                                        error={errors.company_url ? true : false}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Categories"
                                        margin="normal"
                                        name="categories"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.categories}
                                        variant="outlined"
                                        error={errors.categories ? true : false}
                                    />


                                    <DropDownSelect
                                      showReset={false}
                                      value={values.delivery_schedule}
                                      onChange={handleChange}
                                      seleckList={scheduleVariant}
                                      label='Shedule'
                                      name='delivery_schedule'
                                    /> 
<LocalizationProvider locale={en} dateAdapter={AdapterDateFns}>
<Box sx={{pt: 2}}>
      <DatePicker
        sx={{ width: '100%', margin: 20 }}
        label="Start date"
        inputFormat="dd-MMMM-yyyy"
        value={date}
        onChange={handleChangeDate}
        renderInput={(params) => <TextField {...params} />}
      />
</Box>
    </LocalizationProvider>
    <Box sx={{pt: 2}}>

                                     <FormControlLabel 
                                     control={<Checkbox
                                     checked={values.is_enabled}
                                     name="is_enabled"
                                     value={values.is_enabled} 
                                     onChange={handleChangeCheckBox} 
                                            
                                     />} label="Is Active" />
    </Box>


                                    <Alert severity={alert.type}
                                           style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Add
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default CompanyAdd;
