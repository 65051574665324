import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import { useSearchParams} from 'react-router-dom';
import {
    Box,
    Container,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    Divider,
    IconButton,
    TextField,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import UserListSkelet from '../../skeletons/UserListSkelet';
import {useDelete, useGet, usePost} from '../../API/request';
import {useConfirm} from "../../components/Confirm/index";
import '../../styles/All.css'
import { Loader } from '../../components/loader/Loader';
import { formatDate } from '../../shared/service/formattedDate';
import { useNavigate} from 'react-router-dom';
import { ArchiveUploadFile } from '../../features/archive'
import { useUploadFile } from '../../entities';
import ClearIcon from '@mui/icons-material/Clear';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'; 
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {en} from "dayjs/locale/en";


const ManagingCampaignFiles = () => {
  const { uploadFile } = useUploadFile()

    const confirm = useConfirm();
    const getU = useGet();
    const postU = usePost();
    const deleteU = useDelete();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const [isLoaded, setIsLoaded] = useState(true);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [companys, setCompanys] = useState([]);
    const [filesList, setFilesList] = useState([]);
    const [dayList, setDayList] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);
    const [currentDate, setCurrentDate] = useState(null)
    const [dropCurrent, setDropCurrent] = useState(null)

    const [testDate, setTestDate] = React.useState(null)

    const [date, setDate] = React.useState(null)
 

    const [queryParams, setQueryParams] = useState({
        name: searchParams.get("name") || '',
        date: searchParams.get("date") || '',
        company_name: searchParams.get("company_name") || '',
        sort_by_date: searchParams.get("sort_by_date") || '',
        sort_by_company: searchParams.get("sort_by_company") || '',
    });

    const handleChangeDate = (newValue) => {
        setTestDate(newValue)
        // setQueryParams(() => ({
        //     ...queryParams,
        //     date:  formatDate(newValue)
        // }))
      }

     const getStringInDate = (value) => {
      if (!value) return "";
    
      const year = value.getFullYear();
      const month = (value.getMonth() + 1).toString().padStart(2, "0");
      const day = value.getDate().toString().padStart(2, "0");
    
      return `${year}-${month}-${day}`;
    }

    
    const getAllDays = async() => {
        const endpoint = 'day/all'
         getU(endpoint)
         .then(resp => {
            if (resp.status === 'success') {
                setDayList(resp.data.days)
                const allDays = resp.data.days
                const currentDate = new Date()
                const formattedCurrentDate = getStringInDate(currentDate)
        
                let findCurrentDateInAllDays = false

                // allDays.forEach((item) => {
                //   if (item.date === formattedCurrentDate) {
                //     findCurrentDateInAllDays = true
                //     setCurrentDate(item)
                //   } 
                // })
                
                // if (!findCurrentDateInAllDays) {
                //   const endpoing = 'day/create'
                //   postU(endpoing, { date: formattedCurrentDate })
                //   .then((resp) => {
                //     if (resp.status === 'success') {
                //       setCurrentDate({date: resp.data.day.date, day_id: resp.data.day.day_id})
                //     }

                //   })
                //   .catch(error)
                // }

            }
         }) 
    }
 
    const getAllCompany = () => {
        const endpoint = 'company/active'
        getU(endpoint)
        .then(resp => {
           if (resp.status === 'success') {
            setCompanys(resp.data.companies)
            getAllDays()
           }
        }) 
        .catch((err) => {
          console.log(err.response)
          setCompanys([]);
      })
      .finally(() => {
          setIsLoaded(false)
          setIsDataLoading(false);
      });
    }

    const addWeek = () => {
        setIsLoaded(true)

        const endpoint = 'day/addWeek'
        
        const data = {}

        if (testDate) {
            data.testMode = true,
            data.customDate = formatDate(testDate)
        }

        postU(endpoint, data)
        .then(resp => {
           if (resp.status === 'success') {
            setCompanys(resp.data.companies)
            setCurrentDate(resp.data.day)
            // getAllDays()
           }
        }) 
        .catch((err) => {
          console.log(err.response)
          setCompanys([]);
      })
      .finally(() => {
          setIsLoaded(false)
          setIsDataLoading(false);
      });
    }


    const loadFiles = () => {
        setIsDataLoading(true);
        setIsLoaded(true)
 
        let endpoint = `company/active`;

        

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                  console.log(resp)
                    // setFilesList(resp.data.files)
                    getAllDays()
                }
                setIsDataLoading(false);
            })
            .catch((err) => {
                console.log(err.response)
                setCompanys([]);
                setCount(0);
                setIsDataLoading(false);
            })
            .finally(() => {
                setIsLoaded(false)
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };


    const onDelete = (id) => {

        confirm({
            title: 'Delete',
            content: 'Are you sure you want to delete the company?',
            onConfirm: () => {
                deleteU(`company/delete/${id}`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            loadFiles();
                        }
                    })
                    .catch((e) => {
                        // console.log("opened")
                        // console.log(e.response)
                    });
            }
        });
    };

    useEffect(() => {
        if (queryParams.name === '') {
            searchParams.delete("name")
            setSearchParams(searchParams);
        }
        if (queryParams.date === '') {
            searchParams.delete("date")
            setSearchParams(searchParams);
        }
        if (queryParams.company_name === '') {
            searchParams.delete("company_name")
            setSearchParams(searchParams);
        }
        if (queryParams.sort_by_date === '') {
            searchParams.delete("sort_by_date")
            setSearchParams(searchParams);
        }
        if (queryParams.sort_by_company === '') {
            searchParams.delete("sort_by_company")
            setSearchParams(searchParams);
        }
    }, [queryParams])

    useEffect(() => {
        // loadFiles();
        // getAllCompany()
        addWeek()
    }, [ searchParams, date, testDate]);

    if (isLoaded) {
        return  <Loader/>;
    }

    function handleDragOver(e) {
      e.preventDefault();
    }
    
    function handleDrop(e) {
      e.preventDefault();
      const file = e.dataTransfer.files[0];
      if (!file) return;
      const buffer = new FileReader();
    buffer.readAsArrayBuffer(file);
    buffer.onloadend = () => {
      uploadFile({
        company_id: dropCurrent,
        day_id: currentDate.day_id,
        file
      })
    }

    buffer.onerror = () => {
      console.log('Failed File')
    }
    addWeek()
    setDropCurrent(null)
    }

    const handleonDragEnter = (companyID) => {

        setDropCurrent(companyID)
    }

    function handleDragLeave(e) {

        setDropCurrent(null);
      }

    return (
        <>
            <Helmet>
                <title>Active clients</title>
            </Helmet>

            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate('/app/data-lab')}>
                        Back
                    </Button>
                    Data Lab
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate('/app/data-lab')}>Data Lab</li>
                    <li>/</li>
                    <li>Add week</li>
                </ul>
            </Box>

   

      


            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                    {
                        isDataLoading ?
                            <UserListSkelet/>
                            :
                            <>
                                
                                                <LocalizationProvider locale={en} dateAdapter={AdapterDateFns}>
<Box sx={{pt: 2, position: 'relative'}}>
      <DatePicker
      clearable
      
       slotProps={{ field: { clearable: true } }}
        sx={{ width: '100%', margin: 20, backgroundColor: 'white' }}
        label="Test Date"
        inputFormat="dd-MMMM-yyyy"
        value={testDate}
        onChange={handleChangeDate}
        renderInput={(params) => <TextField {...params} />}

      />
        {testDate &&
              <IconButton style={{ position: "realetiv", top: 8, margin: "auto", right: "80px" }} onClick={() => setTestDate(null)}>
                <ClearIcon />
              </IconButton>
            }
</Box>
</LocalizationProvider>
                                <Box sx={{pt: 3}}>
                                    <Card>
                                        <PerfectScrollbar>
                                            <Box sx={{minWidth: 1400}}>
                                                
                                                <Divider/>

                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{width: 80}}>
                                                                Id
                                                            </TableCell>
                                                            <TableCell>
                                                                Company name
                                                            </TableCell>
                                                            <TableCell>
                                                            Campaign start
                                                            </TableCell>
                                                            <TableCell>
                                                            Categories
                                                            </TableCell>
                                                            <TableCell>
                                                            Url
                                                            </TableCell>
                                                            <TableCell>
                                                            Schedule
                                                            </TableCell>
                                                            <TableCell>

                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    
                                                    <TableBody>
                                                        {companys?.map((company) => (
                                                            <TableRow 
                                                            // style={dropCurrent === company?.company_id ? {background: '#baf7c7'} : {}}
                                                            onDragOver={handleDragOver}
                                                            onDrop={handleDrop}
                                                            // onDragLeave={handleDragLeave}
                                                            onDragEnter={() => handleonDragEnter(company?.company_id)}
                                                            hover 
                                                            key={company.company_id}>
                                                                <TableCell sx={{width: 80}}>
                                                                    {company?.company_id}
                                                                </TableCell>

                                                                <TableCell style={{position: 'realitive'}} >
                                                                    {company?.name || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {company?.campaign_start_date ? formatDate(new Date(company?.campaign_start_date)) : '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {company?.categories || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {company?.company_url || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {company?.delivery_schedule || '---'}
                                                                </TableCell>
                                                                <TableCell>


                                                            
                                                                    <Box sx={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end',}}>
                                                                      <ArchiveUploadFile
                                                                      callback={addWeek}
                                                                      company_id={company?.company_id}
                                                                      day_id={currentDate?.day_id}   
                                                                      />
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </PerfectScrollbar>
                                    </Card>
                                </Box>
                            </>
                    }
                </Container>
            </Box>
        </>
    );
};

export default ManagingCampaignFiles;