import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {
    Box,
    Container,
    Button, Card, CardHeader, Divider, CardContent, CircularProgress, TextField, Alert, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useParams, useNavigate} from 'react-router-dom';
import UserFormEdit from '../../components/Users/UserFormEdit';
import {useGet, usePut, usePost, useDelete} from "../../API/request";
import {BallTriangle} from "react-loader-spinner";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import '../../styles/Avatar/style.css'
import { Loader } from '../../components/loader/Loader';

const UserEdit = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const getU = useGet();
    const putU = usePut();
    const postU = usePost();
    const deleteU = useDelete();

    const [isLoaded, setIsLoaded] = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [nothingChanged, setNothingChanged] = useState(true);
    const [currentCompany, setCurrentCompany] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [uploadedImg, setUploadedImg] = useState('/static/images/defphoto.jpg');
    const [values, setValues] = useState({
        email: '',
        username: '',
        role: '',
        password: '',
        confirm: '',
        img: '',
        company: '',
    });
    const [errors, setErrors] = useState({
        email: false,
        username: false,
        role: false,
        password: false,
        confirm: false,
        img: false
    });
    const [roles, setRoles] = useState([])


    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

                                        // REMOVE
    // ___________________________________________________________________________________
   
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleDeleteClient = () => {
        deleteU(`clients/delete/${id}`)
        .then(resp => {
            navigate('/app/users')
            // if (resp.status === 'success') {
            //     showAlert('success', 'Company delete');
            // } else {
            //     showAlert('error', 'Error');
            // }
        })
        // .catch((err) => {
        //     showAlert('error', 'Error');
        // })
        // .finally(() => {
        //     setSubmitDisabled(false);
        // })


    }
    
                                         // REMOVE
    // ___________________________________________________________________________________

    const [companyList, setCompanyList] = useState([])

    useEffect(() => {
        getU('company/all')
        .then(resp => {
            setCompanyList(resp.data.companies)
        })
    },[])

    const handleChange = (event) => {
        setNothingChanged(false);

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const handleChangeCompany = (event) => {
        setNothingChanged(false);

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const avaUploaded = (event) => {
        setNothingChanged(false)
        setUploadedImg(URL.createObjectURL(event.target.files[0]));
        setValues({
            ...values,
            img: event.target.files[0]
        });
    };


    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 2500);
    };

    const validateInfo = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.role === '') {
            validComplete = false;
            formErrors.role = false;
            showAlert('error', "The Role field should not be empty")
        }

        if (values.email === '') {
            validComplete = false;
            formErrors.email = false;
            showAlert('error', "The Email field should not be empty")

        }
        if (values.username === '') {
            validComplete = false;
            formErrors.username = false;
            showAlert('error', "The User Name field should not be empty")

        }

        setErrors(formErrors);
        return validComplete;
    };

    const validatePassword = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.password) {
            if (!values.password) {
                validComplete = false;
                formErrors.password = true;
                showAlert('error', "The Password field should not be empty")
            } else if (values.password.length < 8) {
                validComplete = false;
                formErrors.password = true;
                showAlert('error', "The password must contain more than 8 characters")
            }

            if (values.password !== values.confirm) {
                validComplete = false;
                formErrors.confirm = true;
                showAlert('error', "Passwords don't match")
            }
        }

        setErrors(formErrors);
        return validComplete;
    };

    const submitAvatar = async () => {
        if (nothingChanged) {
            showAlert('error', 'No changes');
            return;
        }

        setSubmitDisabled(true);

        let data = new FormData();
        data.append('avatar', values.img);

        putU(`admin/users/avatar/${id}`, data)
            .then((resp) => {
                if (resp.status === 'success') {
                    showAlert('success', 'Data has been successfully updated');
                } else {
                    showAlert('error', 'Error');
                }
            })
            .catch((err) => {
                showAlert('error', 'Error server');
            })
            .finally(() => {
                setSubmitDisabled(false);
            })
        ;

    };


    const submitInfo = async () => {
        if (nothingChanged) {
            showAlert('error', 'No changes');
            return;
        }

        if (validateInfo()) {
            setSubmitDisabled(true);

            const data = {
                username: values.username,
                email: values.email,
                role: values.role
            };

            putU(`admin/users/${id}`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Data has been successfully updated');
                    } else {
                        showAlert('error', 'Ошибка');
                    }
                })
                .catch((err) => {
                    showAlert('error', 'Error server');
                })
                .finally(() => {
                    setSubmitDisabled(false);
                })
            ;
        }
    };

    const submitPassword = async () => {
        if (nothingChanged) {
            showAlert('error', 'No changes');
            return;
        }

        if (validatePassword()) {
            setSubmitDisabled(true);

            const data = {
                password: values.password
            };

            putU(`admin/users/password/${id}`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Data has been successfully updated');
                        setValues({...values, password: '', confirm: ''})
                    } else {
                        showAlert('error', 'Error');
                    }
                })
                .catch((err) => {
                    showAlert('error', 'Server error');
                })
                .finally(() => {
                    setSubmitDisabled(false);
                })
            ;
        }
    };


    const submitCompany = async () => {
        if (nothingChanged) {
            showAlert('error', 'No changes');
            return;
        }
        
        if (validatePassword()) {
            setSubmitDisabled(true);

            const data = {
                id,
                company_id: currentCompany,
            };

            postU(`clients/select-company`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Data has been successfully updated');
                        setValues({...values, password: '', confirm: ''})
                    } else {
                        showAlert('error', 'Ошибка');
                    }
                })
                .catch((err) => {
                    showAlert('error', 'Server error');
                })
                .finally(() => {
                    setSubmitDisabled(false);
                })
            ;
        }
    };

    useEffect(() => {
        setIsLoaded(true)
        getU(`admin/users/${id}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    const data = {
                        username: resp.data.user.username,
                        email: resp.data.user.email,
                        role: resp.data.user.role
                    }

                    const avatar = resp.data.user.avatar
                        ? `${process.env.REACT_APP_API_URL}/uploads/avatars/${resp.data.user.avatar}`
                        : ''

                    setValues(data)

                    setUploadedImg(avatar)
                }
            })
            .catch(() => {
                showAlert('error', 'An error occurred when loading roles, try to re-upload');
            })
            .finally(() => {

            });
        getU(`roles`)
            .then((resp) => {
                if (resp.status === 'success') {
                    setRoles(resp.data.roles)
                }
            })
            .catch(() => {

            })
            .finally(() => {
                setIsLoaded(false)
            });
    }, []);


    if (isLoaded) {
        return  <Loader/>;
    }

    return (
        <>

<Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>Delete user</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Are you sure you want to delete the user ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClient} color="error">
            Delete
          </Button>
          <Button onClick={() => setIsModalOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>




            <Helmet>
                <title>Edit user</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                    Users
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Users</li>
                    <li>/</li>
                    <li>Edit</li>
                </ul>

            </Box>

            {/*image*/}
            <Box sx={{backgroundColor: 'background.default', pt: 3}}>

                <Container maxWidth={false}>
                    <Box sx={{mb: 1}}>
                        <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                            {alert.txt}
                        </Alert>
                    </Box>
                    <Box sx={{
                        paddingBottom: 1
                    }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Editing an avatar"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                    <div className="itemWrapper">
                                        <div className="container">
                                            <input accept="xlsx/*" type="file" style={{display: 'none'}}
                                                   id={1}
                                                   onChange={(event) => avaUploaded(event, 1)}/>
                                            <label htmlFor={1}>
                                                <img src={uploadedImg} className="itemImg"/>
                                                <div className="middle"/>
                                            </label>
                                        </div>
                                        <div className="help-text">
                                        The following extensions are available: .png .jpg .svg .bmp .tga .webp
                                        </div>
                                    </div>
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submitAvatar}
                                        disabled={submitDisabled}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>

            {/*info*/}
            <Box sx={{backgroundColor: 'background.default', pt: 3}}>

                <Container maxWidth={false}>
                    <Box sx={{
                        paddingBottom: 1
                    }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Editing a user"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                    <TextField
                                        fullWidth
                                        label="User name"
                                        margin="normal"
                                        name="username"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.username}
                                        variant="outlined"
                                        error={errors.username}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Email"
                                        margin="normal"
                                        name="email"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.email}
                                        variant="outlined"
                                        error={errors.email}
                                    />
                                    <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                                        <InputLabel id="role">
                                            Role
                                        </InputLabel>
                                        <Select
                                            labelId="Role"
                                            name="role"
                                            value={values.role}
                                            label="Role"
                                            onChange={handleChange}
                                        >
                                            {
                                                roles?.map((item) => <MenuItem value={item}>{item}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submitInfo}
                                        disabled={submitDisabled}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>


                                              {/* ROLE START */}
{/* ------------------------------------------------------------------------------------------------------------------ */}            

            <Box sx={{backgroundColor: 'background.default', pt: 3}}>
                <Container maxWidth={false}>
                    <Box>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Editing a company"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>


                                <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                                        <InputLabel id="role">
                                        Company
                                        </InputLabel>
                                        <Select
                                            labelId="Company"
                                            name="company"
                                            value={values.company}
                                            label="Company"
                                            onChange={handleChangeCompany}
                                        >
                                            {
                                                companyList.length > 0 &&  companyList.map((company) => <MenuItem onClick={() => setCurrentCompany(company.company_id)} key={company.company_id} value={company.name}>{company.name}</MenuItem>)
                                              
                                            }
                                        </Select>
                                    </FormControl>

                                    
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submitCompany}
                                        disabled={submitDisabled}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>

                                                   {/* ROLE END */}
{/* ------------------------------------------------------------------------------------------------------------------ */}


            {/*password*/}
            <Box sx={{backgroundColor: 'background.default', pt: 3}}>
                <Container maxWidth={false}>
                    <Box>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Password Editing"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                    <TextField
                                        sx={{mb: 2}}
                                        fullWidth
                                        label="Password"
                                        name="password"
                                        onChange={handleChange}
                                        required
                                        type="password"
                                        value={values.password}
                                        variant="outlined"
                                        error={errors.password}
                                        helperText={errors.password && 'Password shouldn\'t be shorter than 6 characters'}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Confirm"
                                        name="confirm"
                                        onChange={handleChange}
                                        type="password"
                                        required
                                        value={values.confirm}
                                        variant="outlined"
                                        error={errors.confirm}
                                        helperText={errors.confirm && 'Passwords are different'}
                                    />
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submitPassword}
                                        disabled={submitDisabled}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>

                                            {/* REMOVE */}
{/* ------------------------------------------------------------------------------------------------------------------ */}

{/* <Box sx={{backgroundColor: 'background.default', pt: 3}}>

<Container maxWidth={false}>
    <Box sx={{
        paddingBottom: 1
    }}>
    
        <form >
            <Card color='red' sx={{ borderColor: 'red' }} styles={{borderColor: 'red'}}>
                <CardHeader sx={{  borderColor: 'error' }}
                    title="Danger Zone"
                    color='red'
                />
                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2, borderColor: 'red'}}>
                    <Button
                        color="error"
                        variant="contained"
                        onClick={handleOpenModal}
                        disabled={submitDisabled}
                    >
                        Delete this client
                    </Button>
                </Box>
            </Card>
        </form>


    </Box>
</Container>
</Box> */}
                                            {/* REMOVE  END*/}
{/* ------------------------------------------------------------------------------------------------------------------ */}



        </>
    );
};

export default UserEdit;
