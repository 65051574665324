import { ArchiveInitState } from "./types";

const initialState: ArchiveInitState = {
  currentDayCell: null,
  files: [],
  days: [],
  loading: false,
  error: null,
  loadingAddDay: false,
  loadingAddFile: false,
  loadingFiles: false,
  success: null,
};

export { initialState };
