import type { ArchiveService } from '../application/port';
import { ArchiveApi } from '../api';

export const archiveAdapter: ArchiveService = {
  createDay(payload) {
    return ArchiveApi.createDay(payload)
  },
  getFiles(payload) {
    return ArchiveApi.getFiles(payload)
  },
  downloadFile(payload) {
      return ArchiveApi.downloadFile(payload)
  },
  uploadFile(payload) {
    return ArchiveApi.uploadFile(payload)
  },
  getAllDays() {
    return ArchiveApi.getAllDays()
  },
};
