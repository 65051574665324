import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import {
  fetchGetAllDay,
  fetchUploadFile,
  fetchCreateDay,
  fetchGetFiles,
  fetchRemoveFile,
} from "./thunk";
import { ArchiveInitState } from "./types";

export const extraReducers = (
  builder: ActionReducerMapBuilder<ArchiveInitState>
) => {
  builder
    .addCase(fetchGetAllDay.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchGetAllDay.rejected, (state, action) => {
      state.loading = false;
      state.error = String(action.payload);
    })
    .addCase(fetchGetAllDay.fulfilled, (state, action) => {
      //@ts-ignore
      const sortDay = action.payload.sort((a, b) => b.day_id - a.day_id)
      state.days = sortDay;

      state.loading = false;
      state.error = null;
    });

  builder
    .addCase(fetchRemoveFile.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchRemoveFile.rejected, (state, action) => {
      state.loading = false;
      state.error = String(action.payload);
    })
    .addCase(fetchRemoveFile.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
    });

  builder
    .addCase(fetchGetFiles.pending, (state) => {
      state.loadingFiles = true;
      state.error = null;
    })
    .addCase(fetchGetFiles.rejected, (state, action) => {
      state.loadingFiles = false;
      state.error = String(action.payload);
    })
    .addCase(fetchGetFiles.fulfilled, (state, action) => {
      //@ts-ignore
      state.files = action.payload;

      state.loadingFiles = false;
      state.error = null;
    });

  builder
    .addCase(fetchCreateDay.pending, (state) => {
      state.error = null;
      state.loadingAddDay = true;
    })
    .addCase(fetchCreateDay.rejected, (state, action) => {
      state.loadingAddDay = false;
      state.error = String(action.payload);
    })
    .addCase(fetchCreateDay.fulfilled, (state, action) => {
      state.days.unshift(action.payload);

      state.success = `Day has been added: ${action.payload.date}` 
      state.loadingAddDay = false;
      state.error = null;
    });

  builder
    .addCase(fetchUploadFile.pending, (state) => {
      state.loadingAddFile = true;
      state.error = null;
    })
    .addCase(fetchUploadFile.rejected, (state, action) => {
      state.loadingAddFile = false;
      state.error = String(action.payload);
    })
    .addCase(fetchUploadFile.fulfilled, (state, action) => {
      state.files.push(action.payload);

      state.success = 'the file has been successfully uploaded to the server'
      state.loadingAddFile = false;
      state.error = null;
    });
};
