import type { FC } from "react";
import { useDownLoadFile } from "../../../entities";
import { Button } from "@material-ui/core";


type ArchiveDownloadFileProps = {
  company_id: string;
  day_id: string;
  name: string;
}

const ArchiveDownloadFile: FC<ArchiveDownloadFileProps> = ({ company_id, day_id, name }) => {
  const { downLoadFile, loading } = useDownLoadFile()

  const handleClickDownLoad = () => {
    if (loading) return;
    console.log(company_id, day_id, name)
    downLoadFile({ company_id, day_id, name })
  }

  return (
    <>
    <Button 
    disabled={loading}
    color="primary"
    variant="contained"
    onClick={handleClickDownLoad}
    >
   DownLoad
    </Button>
    </>
  );
};

export { ArchiveDownloadFile };