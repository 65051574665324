import { useAppSelector } from "../../../shared/model";
import { archiveSlice } from "./slice";

const archiveSelect = (state: RootState) => state.archive;
export const archiveAction = archiveSlice.actions;
export const archiveReducer = archiveSlice.reducer;

export const useArchiveSelect = () => {
  return useAppSelector(archiveSelect);
};
