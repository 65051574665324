import React, {useState, useEffect, useRef} from 'react';
import {Helmet} from 'react-helmet';
import {Link, useSearchParams} from 'react-router-dom';
import {
    Box,
    Container,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    Divider,
    TextField,
    Typography,
    IconButton,
    ToggleButtonGroup,
    ToggleButton,
    TableFooter,
    TablePagination
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import {useDelete, useGet} from '../../API/request';
import {useConfirm} from "../../components/Confirm/index";
import '../../styles/All.css'
import { Loader } from '../../components/loader/Loader';
import ClearIcon from '@mui/icons-material/Clear';
import { ArchiveDownloadFile, ArchiveRemoveFile } from '../../features/archive';
import { DropDownSelect } from '../../components/drop-down-select/DropDownSelect'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'; 
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {en} from "dayjs/locale/en";
import { formatDate } from '../../shared/service/formattedDate';
import { archiveAdapter } from '../../entities/archive/service/archiveAdapter';

const DataLabList = () => {

    const confirm = useConfirm();
    const getU = useGet();
    const deleteU = useDelete();
    const [searchParams, setSearchParams] = useSearchParams();
    const downLoadFileRef = useRef(null)

    const [isLoaded, setIsLoaded] = useState(true);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [companys, setCompanys] = useState([]);
    const [filesList, setFilesList] = useState([]);
    const [dayList, setDayList] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);

    const [sortOrder, setSortOrder] = useState('asc');

    const [date, setDate] = React.useState(null)


    const [queryParams, setQueryParams] = useState({
        name: searchParams.get("name") || '',
        date: searchParams.get("date") || '',
        company_name: searchParams.get("company_name") || '',
        sort_by_date: searchParams.get("sort_by_date") || '',
        sort_by_company: searchParams.get("sort_by_company") || '',
    });



    const handleChangeDate = (newValue) => {
        setDate(newValue)
        setQueryParams(() => ({
            ...queryParams,
            date:  formatDate(newValue)
        }))
      }

    const handleChangeQueryParams = (event) => {
        setQueryParams({
            ...queryParams,
            [event.target.name]: event.target.value
        });
    };

    const handleFilterQueryParams = () => {
        const params = {}
        if (queryParams.name !== '') {
            params.name = queryParams.name
        }
        if (queryParams.date !== '') {
            params.date = queryParams.date
        }
        if (queryParams.company_name !== '') {
            params.company_name = queryParams.company_name
        }

        if (queryParams.sort_by_date !== '') {
            params.sort_by_date = queryParams.sort_by_date
        }
        if (queryParams.sort_by_company !== '') {
            params.sort_by_company = queryParams.sort_by_company
        }

        
        if (Object.keys(params).length !== 0) {
            setSearchParams(params)
        }
    }
 
    const getAllCompany = () => {
        const endpoint = 'company/all'
        getU(endpoint)
        .then(resp => {
           if (resp.status === 'success') {
            setCompanys(resp.data.companies)
           }
        }) 
    }

    const getAllDays = async() => {
        const endpoint = 'day/all'
         getU(endpoint)
         .then(resp => {
            if (resp.status === 'success') {
                setDayList(resp.data.days)
                getAllCompany()
            }
         }) 
    }

    const loadFiles = () => {
        setIsDataLoading(true);
        setIsLoaded(true)
        
        let endpoint = `files/getfiles?page=${page + 1}&limit=${limit}`;

        if (queryParams.name !== '') {
            endpoint += `&name=${searchParams.get("name")}`;
        }
        if ( date) {
            endpoint += `&date=${formatDate(date)}`;
            // endpoint += `&date=${searchParams.get("date")}`;
        }
        if (queryParams.company_name !== '') {
            endpoint += `&company_name=${searchParams.get("company_name")}`;
        }

        if (queryParams.sort_by_company !== '') {
            endpoint += `&sort_by_company=${searchParams.get("sort_by_company")}`;
        }
        if (queryParams.sort_by_date !== '') {
            endpoint += `&sort_by_date=${searchParams.get("sort_by_date")}`;
        }

        getU(endpoint)
            .then(async(resp) => {
                if (resp.status === 'success') {
                    setFilesList(resp.data.files)
                    setCount(resp.data.totalCount || 0)
       

                    await  getAllDays() //TODO remove
                }
                setIsDataLoading(false);
            })
            .catch((err) => {
                console.log(err.response)
                setFilesList([]);
                setCount(0);
                setIsDataLoading(false);
            })
            .finally(() => {
                setIsLoaded(false)
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };


    const onDelete = (id) => {

        confirm({
            title: 'Delete',
            content: 'Are you sure you want to delete the company?',
            onConfirm: () => {
                deleteU(`company/delete/${id}`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            loadFiles();
                        }
                    })
                    .catch((e) => {
                        // console.log("opened")
                        // console.log(e.response)
                    });
            }
        });
    };

    useEffect(() => {
        if (queryParams.name === '') {
            searchParams.delete("name")
            setSearchParams(searchParams);
        }
        if (queryParams.date === '') {
            searchParams.delete("date")
            setSearchParams(searchParams);
        }
        if (queryParams.company_name === '') {
            searchParams.delete("company_name")
            setSearchParams(searchParams);
        }
        if (queryParams.sort_by_date  === '') {
            searchParams.delete("sort_by_date")
            setSearchParams(searchParams);
        }
        if (queryParams.sort_by_company  === '') {
            searchParams.delete("sort_by_company")
            setSearchParams(searchParams);
        }
    }, [queryParams])

    useEffect(() => {
        loadFiles();
    }, [ searchParams, date, limit, page]);

    if (isLoaded) {
        return  <Loader/>;
    }

    // const downloadFileFeth = async(params) => { 
    //     const { company_id, day_id, name } = params
    //     const downLoadFileUrl = 'files/download';
      
    //     const mainUrl = 'https://docs.backend.usdemowts.ru/'
    //     const url = `${mainUrl}api/${downLoadFileUrl}?day_id=${day_id}&company_id=${company_id}&name=${name}`;
      
    //     const response = await fetch(url);
      
    //     return await response.blob();
    //   };

    const handleClickDownLoad = async(payload) => {

            console.log(payload)

        const blob = await archiveAdapter.downloadFile(payload)
        const urlObject = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = urlObject;
        link.download = payload.name;
        link.click();
        URL.revokeObjectURL(urlObject);
      };

   const getCompanyName = (company_id) => {
    let result = '---'

    const findCompany = companys.find(company => company.company_id === company_id)
    if (findCompany) {
        result = findCompany.name
    }

    return result
    }

   const getDateCreate = (date_id) => {
    let result = '---'

    const findDay = dayList.find(day => day.day_id === date_id)
    if (findDay) {
        result = findDay.date
    }

    return result
    }



    return (
        <Box sx={{ pb: 3}}>
            <Helmet>
                <title>Date Lab</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                Date Lab
                </Box>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3, pb: 3}}>
                <Container maxWidth={false}>
                    {
                        isDataLoading ?
                            <UserListSkelet/>
                            :
                            <>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Box sx={{marginLeft: 2}}>
                                        <Link to="/app/data-lab/managing">
                                            <Button color="primary" variant="contained">
                                            Add week
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                                <Box sx={{pt: 3}}>
                                    <Card>
                                        <PerfectScrollbar>
                                            <Box sx={{minWidth: 1400}}>
                                                <Box sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: `1fr 1fr 242px 150px 150px 150px`,
                                                    columnGap: 1,
                                                    padding: `0 20px`,
                                                }}>
                                                    <TextField
                                                        fullWidth
                                                        label="File name"
                                                        margin="normal"
                                                        name="name"
                                                        onChange={handleChangeQueryParams}
                                                        type="text"
                                                        value={queryParams.name}
                                                        variant="outlined"
                                                        // style={{width: '30%'}}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="Company name"
                                                        margin="normal"
                                                        name="company_name"
                                                        onChange={handleChangeQueryParams}
                                                        type="text"
                                                        value={queryParams.company_name}
                                                        variant="outlined"
                                                        // style={{width: '30%'}}
                                                    />
<LocalizationProvider locale={en} dateAdapter={AdapterDateFns}>
<Box sx={{pt: 2, position: 'relative'}}>
      <DatePicker
     
      clearable
       slotProps={{ field: { clearable: true } }}
        sx={{ width: '100%', margin: 20 }}
        label="Date"
        inputFormat="dd-MMMM-yyyy"
        value={date}
        onChange={handleChangeDate}
        renderInput={(params) => <TextField {...params} />}

      />
        {date &&
              <IconButton style={{ position: "absolute", top: 23, margin: "auto", right: "2rem" }} onClick={() => setDate(null)}>
                <ClearIcon />
              </IconButton>
            }
</Box>
    </LocalizationProvider>
    <Box sx={{pt: 0, position: 'relative',}}>
                                    <DropDownSelect
                                      value={queryParams.sort_by_date}
                                      onChange={handleChangeQueryParams}
                                      seleckList={['asc','desc']}
                                      label='Sort date'
                                      name='sort_by_date'
                                      
                                    /> 
                                    </Box>
    <Box sx={{pt: 0, position: 'relative', }}>
                                    <DropDownSelect
                                      value={queryParams.sort_by_company}
                                      onChange={handleChangeQueryParams}
                                      seleckList={['asc','desc']}
                                      label='Sort company'
                                      name='sort_by_company'
                                    /> 
                                    </Box>






                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={handleFilterQueryParams}
                                                        sx={{mt: 2, mb: 1}}
                                                    >
                                                        Apply
                                                    </Button>
                                                </Box>
                                                <Divider/>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell  sx={{maxWidth: 200}}>
                                                              File Name
                                                            </TableCell>
                                                            <TableCell >
                                                              Client name
                                                            </TableCell>
                                                            <TableCell >
                                                              Date create
                                                            </TableCell>
                                                            <TableCell>
                                                            </TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {filesList?.map((file, index) => (
                                                            <TableRow hover key={index}>

                                                                <TableCell  sx={{width: 440, minHeight: 200, lineHeight: 1.5}}>
                                                                    {file?.name || '---'}
                                                                </TableCell>

                                                                <TableCell>
                                                                    {getCompanyName(file?.company_id)}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {getDateCreate(file?.day_id)}
                                                                </TableCell>


                                                                <TableCell>
                                                                    <Box sx={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end',}}>

                                                                        <Box sx={{display: 'flex',}}>
                                                                       
                                                                                {/* <Button 
                                                                                 onClick={() => downLoadFileRef.current?.click()}       
                                                                                color="primary"
                                                                                variant="contained">
                                                                                    Upload
                                                                                </Button> */}
                       {/* -------------------------------------------------------------------------------------------- */}
                                                                            {/* <Box sx={{ml: 2}}> 
                                                                            <ArchiveDownloadFile
                                                                            company_id={file?.company_id}
                                                                            day_id={file?.day_id}
                                                                            name={file?.name}

                                                                            />     
                                                                            </Box>  */}
                                                                            <Button 
  
    color="primary"
    variant="contained"
    onClick={() => handleClickDownLoad({ company_id: file?.company_id, day_id: file?.day_id, name: file?.name})}
    >
   DownLoad
    </Button>
                                                                         
                        {/* -------------------------------------------------------------------------------------------- */}

                                                                        {/* <Box sx={{ml: 2}}>
                                                                            <Button color="error"
                                                                                 onClick={() => onDelete(file.company_id)}
                                                                                 variant="contained">
                                                                                 Delete
                                                                                 </Button>
                                                                        </Box> */}



                                                                        </Box>
                                                                    </Box>
                                                                </TableCell>
                                                                
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                labelRowsPerPage={
                                                                    <span>Number of lines per page:</span>}
                                                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                                colSpan={7}
                                                                count={count}
                                                                rowsPerPage={limit}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeLimit}
                                                            />
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </Box>
                                        </PerfectScrollbar>
                                    </Card>
                                </Box>
                            </>
                    }
                </Container>
            </Box>
                    
        </Box>
    );
};

export default DataLabList;
