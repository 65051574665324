import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Avatar,
  Container,
  Button,
  Card,
  CardContent,
  Alert
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { useGet } from '../../API/request';
import '../../styles/All.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Loader } from '../../components/loader/Loader';
import { InfoFieldItem } from '../../components/info-field-item/InfoFieldItem';

const UserInfo = () => {
  const navigate = useNavigate();
  const getU = useGet();
  const { id } = useParams();

  const [isLoaded, setIsLoaded] = useState(true);

  const [user, setUser] = useState({});
  const [myCompany, setMyCompany] = useState(null);

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });

    }, 1400);
  };


const fethAllCompanys = async(companyId) => {
    if (!companyId) return;

    setIsLoaded(true);
    getU('company/all')
        .then((resp) => {
            if (resp.status === 'success') {
                const companies = resp.data.companies
                const findMyCompany = companies.find(item => item.company_id === companyId)
                if (findMyCompany) {
                    setMyCompany(findMyCompany) 
                }  
            }
    })
    .catch(() => {
        showAlert('error', 'Failed to loading company');
        setIsLoaded(false); 
    })
    .finally(() => {
        setIsLoaded(false);
    });
    // getU(`company/myCompany/1`) //TODO dont work
    //     .then((resp) => {
    //         if (resp.status === 'success') {
    //             console.log(resp)
    //             // setCompanyList(resp.data.companies);
    //         }
    // })
    // .catch(() => {
    //     showAlert('error', 'Failed to loading my company');
    //     setIsLoaded(false); 
    // })
    // .finally(() => {
    //     setIsLoaded(false);
    // })
}

  const fetchGetUserInfo = async () => {
    setIsLoaded(true);
    getU(`admin/users/${id}`)
      .then((resp) => {
        if (resp.status === 'success') {
          setUser(resp.data.user);
          if (resp.data.user?.company_id) {
              fethAllCompanys(resp.data.user?.company_id)
            }
        }
      })
      .catch(() => {
        showAlert('error', 'Ошибка сервера');
        setIsLoaded(false);
      })
      .finally(() => {
        setIsLoaded(false);
      }); 
  };


  useEffect(() => {
    fetchGetUserInfo()
  }, []);

  if (isLoaded) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>User</title>
      </Helmet>

      <Alert
        severity={alert.type}
        style={{ display: alert.isVisible ? 'flex' : 'none' }}
      >
        {alert.txt}
      </Alert>

      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Back
          </Button>
          Users
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Users</li>
          <li>/</li>
          <li>Info</li>
        </ul>
      </Box>

      <Box sx={{ backgroundColor: 'background.default', pt: 3, pb: 1 }}>
        <Container maxWidth={false}>
          <Card>
            <CardContent sx={{ p: 3 }}>
              <PerfectScrollbar>
                <div className="wrapAvatar">
                  <div className="avatar-block">
                    <Avatar
                      src={`${process.env.REACT_APP_API_URL}/uploads/avatars/${user.avatar}`}
                      className="avatar"
                    />
                  </div>
                  <div className="info-block">
                    <InfoFieldItem
                      widthLabel={100}
                      label="ID:"
                      item={user?.id}
                    />
                    <InfoFieldItem
                      widthLabel={100}
                      label="User name:"
                      item={user?.username}
                    />
                    <InfoFieldItem
                      widthLabel={100}
                      label="Email:"
                      item={user?.email}
                    />
                    <InfoFieldItem
                      widthLabel={100}
                      label="Role:"
                      item={user?.role}
                    />
                    <InfoFieldItem
                      href={user.company_id ? `/app/company/${user.company_id}` : null}
                      widthLabel={100}
                      label="Company:"
                      item={myCompany?.name ? myCompany?.name : '---'}
                    />
                  </div>
                </div>
              </PerfectScrollbar>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default UserInfo;
