import { useState } from "react";
import { useAppDispatch } from "../../../shared/model";
import { CreateDayInput, DownLoadFileInput, GetFilesParamsInput, UploadFileInput } from "../DTO/archive-dto";
import { archiveAction, useArchiveSelect } from "../model";
import { fetchCreateDay, fetchGetAllDay, fetchGetFiles, fetchUploadFile } from "../model/thunk";
import { archiveAdapter } from "../service/archiveAdapter";


export function useFetchFiles() {
  const { error, loading, files } = useArchiveSelect();
  const dispatch = useAppDispatch();

  const getFiles = (payload : GetFilesParamsInput) => {
    dispatch(fetchGetFiles(payload))
  }

  return { error, files, loading, getFiles };
}

export function useDownLoadFile() {
  const [loading, setLoading] = useState<boolean>(false)

  const downLoadFile = async(payload: DownLoadFileInput) => {
    setLoading(true)
    const blob = await archiveAdapter.downloadFile(payload)
    
    const urlObject = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = urlObject;
    link.download = payload.name;
    link.click();
    URL.revokeObjectURL(urlObject);
    setLoading(false)
  };

  return { downLoadFile, loading };
}

export function useUploadFile() {
  const dispatch = useAppDispatch();

  const uploadFile = async(payload: UploadFileInput, callback: () => void) => {
  await  dispatch(fetchUploadFile(payload));
  callback()
  };

  return { uploadFile };
}

export function useRemoveFile() {
  const dispatch = useAppDispatch();

  const uploadFile = (payload: UploadFileInput) => {
    dispatch(fetchUploadFile(payload));
  };

  return { uploadFile };
}

export function useCreateDay() {
  const dispatch = useAppDispatch();

  const createDay = (payload: CreateDayInput) => {
    dispatch(fetchCreateDay(payload));
  };

  return { createDay };
}

export function useGetAllDays() {
  const dispatch = useAppDispatch();

  const getAllDays = () => {
    dispatch(fetchGetAllDay());
  };

  return { getAllDays };
}

export function useSetCurrentDayCell() {
  const dispatch = useAppDispatch();

  const setCurrentDayCell = (payload: { id: number }) => {
    dispatch(archiveAction.setCurrentDayCell(payload));
  };

  return { setCurrentDayCell };
}

export function useResetNotification() {
  const dispatch = useAppDispatch();

  const resetNotification = () => {
    dispatch(archiveAction.resetNotification());
  };

  return { resetNotification };
}

