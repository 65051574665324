import { createAppThunk } from "../../../shared/model/thunk";
import {
  CreateDayInput,
  GetFilesParamsInput,
  UploadFileInput
} from "../DTO/archive-dto";
import { archiveAdapter } from "../service/archiveAdapter";


export const fetchUploadFile = createAppThunk(
  "archive/fetchUploadFile",
  async (payload: UploadFileInput, { rejectWithValue }) => {

    const response = await archiveAdapter.uploadFile(payload);

    if (response.status === "error" || !response.data) {
      return rejectWithValue(
        `Failed fetch  all promocodes: ${response.message}`
      );
    }

    return { name: response.data.file.name, file_path: response.data.file.file_path };
  }
);

export const fetchRemoveFile = createAppThunk(
  "archive/fetchRemoveFile",
  async (payload: UploadFileInput, { rejectWithValue }) => {
    const response = await archiveAdapter.uploadFile(payload);
    
    if (response.status === "error") {
      return rejectWithValue(
        `Failed fetch  all promocodes: ${response.message}`
      );
    }

   
    return {};
  }
);


export const fetchCreateDay = createAppThunk(
  "archive/fetchCreateDay",
  async (payload: CreateDayInput, { rejectWithValue }) => {
    const response = await archiveAdapter.createDay(payload);

    console.log("fetchCreateDay:", response);

    if (response.status === "error" || !response.data) {
      return rejectWithValue(
        `Failed fetch  all promocodes: ${response.message}`
      );
    }

   
    return response.data.day;
  }
);

export const fetchGetAllDay = createAppThunk(
  "archive/fetchGetAllDay",
  async (_: void, { rejectWithValue }) => {
    const response = await archiveAdapter.getAllDays();

    if (response.status === "error" || !response.data) {
      return rejectWithValue(
        `Failed fetch get all days: ${response.message}`
      );
    }

    return response.data.days;
  }
);

export const fetchGetFiles = createAppThunk(
  "archive/fetchGetFiles",
  async (payload: GetFilesParamsInput, { rejectWithValue }) => {
    const response = await archiveAdapter.getFiles(payload);

    if ('status' in response && response.status === "error") {
      return rejectWithValue(
        `Failed fetch get files day adn company: ${response.message}`
      );
    }

      return response;
  }
);

