import { configureStore } from '@reduxjs/toolkit'
//@ts-ignore
import { combineReducers } from "@reduxjs/toolkit";
import { archiveReducer } from '../entities';

//@ts-ignore
import { authReducer } from './slices/auth';

export const rootReducer = combineReducers({
    auth: authReducer,
    archive: archiveReducer,
  });

export const store = configureStore({
    reducer: rootReducer
})

export type AppDispatch = typeof store.dispatch;

