import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Avatar,
  Container,
  Button,
  Card,
  CardContent
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { useGet } from '../../API/request';
import '../../styles/All.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { Loader } from '../../components/loader/Loader';
import { InfoFieldItem } from '../../components/info-field-item/InfoFieldItem'

const CompanyInfo = () => {
  const navigate = useNavigate();
  const getU = useGet();
  const { id } = useParams();

  const [isLoaded, setIsLoaded] = useState(true);

  const [companyList, setCompanyList] = useState({});

  useEffect(() => {
    setIsLoaded(true);
    getU(`company/all`)
      .then((resp) => {
        if (resp.status === 'success') {
          const currentCompany = resp.data.companies.filter(
            (company) => company.company_id === Number(id)
          );

          setCompanyList(...currentCompany);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoaded(false);
      });
  }, []);

  if (isLoaded) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>User</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Back
          </Button>
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Companies</li>
          <li>/</li>
          <li>Info</li>
        </ul>
      </Box>

      <Box sx={{ backgroundColor: 'background.default', pt: 3, pb: 1 }}>
        <Container maxWidth={false}>
          <Card>
            <CardContent sx={{ p: 3 }}>
              <PerfectScrollbar>
                <div className="wrapAvatar">
                  <div className="info-block">
                    <InfoFieldItem label='ID' item={companyList?.company_id} />
                    <InfoFieldItem label='Name' item={companyList?.name} />
                    <InfoFieldItem label='Categories' item={companyList?.categories} />
                    <InfoFieldItem label='Url' item={companyList?.company_url} />
                    <InfoFieldItem label='Schedule' item={companyList?.delivery_schedule} />

                    <div className="wrap">
                      <div className="label" style={{ minWidth: 200 }}>
                        Is Active
                      </div>
                      <div className="text">
                        {companyList.is_enabled === 1 ? (
                          <DoneIcon
                            style={{ position: 'relative', top: -2 }}
                            color="success"
                          />
                        ) : (
                          <CloseIcon
                            style={{ position: 'relative', top: -2 }}
                            color="error"
                          />
                        )}
                      </div>
                    </div>

                  </div>
                </div>
              </PerfectScrollbar>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default CompanyInfo;
