import {Navigate} from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import Account from './pages/Account/Account';
import UserInfo from './pages/User/UserInfo';
import UserEdit from './pages/User/UserEdit';
import UserList from './pages/User/UserList';
import Login from './pages/Login/Login';
import NotFound from './pages/NotFound/NotFound';
import Logs from "./pages/Systems/Logs/Logs";
import Dashboard from "./pages/Dashboard/Dashboard";
import EmailTemplateList from "./pages/Templates/Email-Templates/EmailTemplateList";
import EmailTemplateCreate from "./pages/Templates/Email-Templates/EmailTemplateCreate";
import EmailTemplateEdit from "./pages/Templates/Email-Templates/EmailTemplateEdit";
import CommonSettings from "./pages/Systems/CommonSettings/CommonSettings";
import UpdateSystem from "./pages/Systems/UpdateSystem/UpdateSystem";
import SettingsExampl from "./pages/Settings/SettingsExampl";
import UserAdd from "./pages/User/UserAdd";
import EmailHistoryList from "./pages/Templates/Email-Hstory/EmailHistoryList";
import FeedBackList from "./pages/FeedBacks/FeedBackList";
import FeedBackEdit from "./pages/FeedBacks/FeedBackEdit";
import ConfigurationKeysList from './pages/ConfigurationKeys/ConfigurationKeysList';
import ConfigurationKeysAdd from './pages/ConfigurationKeys/ConfigurationKeysAdd';
import ConfigurationKeysEdit from './pages/ConfigurationKeys/ConfigurationKeysEdit';
import AppLogs from './pages/AppWork/AppLogs';
import AppStatistics from './pages/AppStatistics/AppStatistics';
import AppStatisticsEventsList from './pages/AppStatistics/AppStatisticsEventsList';
import AppStatisticsEventsAdd from './pages/AppStatistics/AppStatisticsEventsAdd';
import AppStatisticsEventsEdit from './pages/AppStatistics/AppStatisticsEventsEdit';
import CompanyList from './pages/Company/CompanyList';
import CompanyInfo from './pages/Company/CompanyInfo';
import CompanyAdd from './pages/Company/CompanyAdd';
import CompanyEdit from './pages/Company/CompanyEdit';
import DataLabList from './pages/DataLab/DataLabList';
import ManagingCampaignFiles from './pages/DataLab/ManagingCampaignFiles';

const routes = [
    {
        path: 'app',
        element: <DashboardLayout/>,
        children: [
            {path: 'account', element: <Account/>},
            {path: 'dashboard', element: <Dashboard/>},

            {path: 'user/:id', element: <UserInfo/>},
            {path: 'user/edit/:id', element: <UserEdit/>},
            {path: 'user/add', element: <UserAdd/>},
            {path: 'users', element: <UserList/>},
            
            {path: 'companys', element: <CompanyList/>},
            {path: 'company/:id', element: <CompanyInfo/>},
            {path: 'company/add', element: <CompanyAdd/>},
            {path: 'company/edit/:id', element: <CompanyEdit/>},

            {path: 'data-lab', element: <DataLabList/>},
            {path: 'data-lab/managing', element: <ManagingCampaignFiles/>},

            {path: 'feedbacks/edit/:id', element: <FeedBackEdit/>},
            {path: 'feedbacks', element: <FeedBackList/>},

            { path: 'email-templates', element: <EmailTemplateList /> },
            { path: 'email-history', element: <EmailHistoryList /> },
            { path: 'email-templates/create', element: <EmailTemplateCreate /> },
            { path: 'email-templates/edit/:id', element: <EmailTemplateEdit /> },

            {path: 'app-logs', element: <AppLogs/>},

            {path: 'app-statistics', element: <AppStatistics/>},
            {path: 'app-statistics/events', element: <AppStatisticsEventsList/>},
            {path: 'app-statistics/events/add', element: <AppStatisticsEventsAdd/>},
            {path: 'app-statistics/events/edit/:id', element: <AppStatisticsEventsEdit/>},

            {path: 'logs', element: <Logs/>},
            {path: 'common-settings', element: <CommonSettings/>},
            {path: 'update-system', element: <UpdateSystem/>},


            {path: 'settings', element: <SettingsExampl/>},

            {path: 'configuration/keys', element: <ConfigurationKeysList/>},
            {path: 'configuration/keys/add', element: <ConfigurationKeysAdd/>},
            {path: 'configuration/keys/edit/:id', element: <ConfigurationKeysEdit/>},


            {path: '', element: <Navigate to="/app/users"/>},
            {path: '*', element: <Navigate to="/404"/>}
        ]
    },
    {
        path: '/',
        element: <MainLayout/>,
        children: [
            {path: 'login', element: <Login/>},
            {path: '404', element: <NotFound/>},
            {path: '/', element: <Navigate to="/app/users"/>},
            {path: '*', element: <Navigate to="/404"/>}
        ]
    }
];

export default routes;
