import { Box, Button } from "@material-ui/core";
import { FC, useRef } from "react";
import { useUploadFile } from "../../../entities";


type ArchiveUploadFileProps = {
  company_id: string,
  day_id: string,
  callback: () => void;
}

const ArchiveUploadFile: FC<ArchiveUploadFileProps> = ({ company_id, day_id, callback }) => {
  const downLoadFileRef = useRef<HTMLInputElement>(null)
  const { uploadFile } = useUploadFile()

  const handleUploadFile = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files ? e.target.files[0] : false;

    if (!file) return;

    const buffer = new FileReader();
    buffer.readAsArrayBuffer(file);
    
    buffer.onloadend = () => {
      uploadFile({company_id,day_id,file}, callback)
    }

    buffer.onerror = () => {
      console.log('Failed File')
    }
  };

  return (
    <>
      <input ref={downLoadFileRef} type="file" onChange={handleUploadFile} hidden />
    

     <Box sx={{display: 'flex', alignItems: 'flex-end',}}>
      <Button 
       onClick={() => downLoadFileRef.current?.click()}
       color="primary"
      variant="contained">
      Upload file
      </Button>
  </Box>     
    </>
  );
};

export { ArchiveUploadFile };