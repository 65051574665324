import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link, useSearchParams} from 'react-router-dom';
import {
    Box,
    Container,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import {useDelete, useGet} from '../../API/request';
import {useConfirm} from "../../components/Confirm/index";
import '../../styles/All.css'
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { Loader } from '../../components/loader/Loader';
import { formatDate } from '../../shared/service/formattedDate';

const CompanyList = () => {

    const confirm = useConfirm();
    const getU = useGet();
    const deleteU = useDelete();
    const [searchParams, setSearchParams] = useSearchParams();

    const [isLoaded, setIsLoaded] = useState(true);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [companys, setCompanys] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);



    const [queryParams, setQueryParams] = useState({
        username: searchParams.get("username") || '',
        email: searchParams.get("email") || '',
        id: searchParams.get("id") || '',
    });

    const handleChangeQueryParams = (event) => {
        setQueryParams({
            ...queryParams,
            [event.target.name]: event.target.value
        });
    };

    const handleFilterQueryParams = () => {
        const params = {}
        if (queryParams.username !== '') {
            params.username = queryParams.username
        }
        if (queryParams.id !== '') {
            params.id = queryParams.id
        }
        if (queryParams.email !== '') {
            params.email = queryParams.email
        }
        console.log(Object.keys(params).length !== 0)
        if (Object.keys(params).length !== 0) {
            setSearchParams(params)
        }
    }

    const loadCompany = () => {
        setIsDataLoading(true);
        setIsLoaded(true)
     
        let endpoint = `company/all`;

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setCompanys(resp.data.companies);
                    setCount(resp.data.currentCount || 0);
                }
                setIsDataLoading(false);
            })
            .catch((err) => {
                console.log(err.response)
                setCompanys([]);
                setCount(0);
                setIsDataLoading(false);
            })
            .finally(() => {
                setIsLoaded(false)
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };


    const onDelete = (id) => {

        confirm({
            title: 'Delete',
            content: 'Are you sure you want to delete the company?',
            onConfirm: () => {
                deleteU(`company/delete/${id}`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            loadCompany();
                        }
                    })
                    .catch((e) => {
                        // console.log("opened")
                        // console.log(e.response)
                    });
            }
        });
    };

    useEffect(() => {
        if (queryParams.username === '') {
            searchParams.delete("username")
            setSearchParams(searchParams);
        }
        if (queryParams.id === '') {
            searchParams.delete("id")
            setSearchParams(searchParams);
        }
        if (queryParams.email === '') {
            searchParams.delete("email")
            setSearchParams(searchParams);
        }
    }, [queryParams])

    useEffect(() => {
        loadCompany();
    }, [page, limit, searchParams]);

    if (isLoaded) {
        return  <Loader/>;
    }

    return (
        <>
            <Helmet>
                <title>Active clients</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                Active clients
                </Box>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                    {
                        isDataLoading ?
                            <UserListSkelet/>
                            :
                            <>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Box sx={{marginLeft: 2}}>
                                        <Link to="/app/company/add">
                                            <Button color="primary" variant="contained">
                                            Add New Campaign
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                                <Box sx={{pt: 3}}>
                                    <Card>
                                        <PerfectScrollbar>
                                            <Box sx={{minWidth: 1400}}>
                                                <Divider/>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{width: 80}}>
                                                                Id
                                                            </TableCell>
                                                            <TableCell>
                                                                Company name
                                                            </TableCell>
                                                            <TableCell>
                                                              
                                                            </TableCell>
                                                            <TableCell>
                                                            Campaign start
                                                            </TableCell>
                                                            <TableCell>
                                                            Categories
                                                            </TableCell>
                                                            <TableCell>
                                                            Url
                                                            </TableCell>
                                                            <TableCell>
                                                            Schedule
                                                            </TableCell>
                                                            <TableCell>

                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {companys?.map((company) => (
                                                            <TableRow hover key={company.company_id}>
                                                                <TableCell sx={{width: 80}}>
                                                                    {company?.company_id}
                                                                </TableCell>

                                                                <TableCell style={{position: 'realitive'}} >
                                                                    {company?.name || '---'}
                                                                </TableCell>
                                                                <TableCell style={{position: 'realitive',  width: 15  }} >
                                                                    {company?.is_enabled === 0 ?
                                                                    <CloseIcon  style={{position: 'relative', top: 3, width: 15}} color='error'/>
                                                                    :
                                                                    <DoneIcon style={{position: 'relative', top: 3,  width: 15}} color='success'/>
                                                                     }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {company?.campaign_start_date ? formatDate(new Date(company?.campaign_start_date)) : '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {company?.categories || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {company?.company_url || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {company?.delivery_schedule || '---'}
                                                                </TableCell>
                                                                <TableCell>



                                                                    <Box sx={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end',}}>

                                                                        <Box sx={{display: 'flex', alignItems: 'flex-end',}}>
                                                                            <Link to={`/app/company/${company.company_id}`}>
                                                                                <Button color="primary"
                                                                                        variant="contained">
                                                                                    Info
                                                                                </Button>
                                                                            </Link>
                                                                            <Box sx={{ml: 2}}>
                                                                                <Link
                                                                                    to={`/app/company/edit/${company.company_id}`}
                                                                                    style={company?.role === "admin" ? {pointerEvents: 'none'} : {}}>
                                                                                    <Button color="primary"
                                                                                            variant="contained"
                                                                                            disabled={company?.role === "admin"}
                                                                                    >
                                                                                        Edit
                                                                                    </Button>
                                                                                </Link>
                                                                            </Box>

                                                                     
{/* ________________________________________________________________________________DELETE___________________________________________ */}
                                                                        <Box sx={{ml: 2}}>
                                                                            <Button color="error"
                                                                                 onClick={() => onDelete(company.company_id)}
                                                                                 variant="contained">
                                                                                 Delete
                                                                                 </Button>
                                                                            </Box>
{/* _______________________________________________________________________________________________________________________________________ */}       

                                                                        </Box>
                                                                    </Box>




                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                    {/* <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                labelRowsPerPage={
                                                                    <span>Number of lines per page:</span>}
                                                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                                colSpan={7}
                                                                count={count}
                                                                rowsPerPage={limit}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeLimit}
                                                            />
                                                        </TableRow>
                                                    </TableFooter> */}
                                                </Table>
                                            </Box>
                                        </PerfectScrollbar>
                                    </Card>
                                </Box>
                            </>
                    }
                </Container>
            </Box>
        </>
    );
};

export default CompanyList;
