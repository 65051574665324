import { AxiosService } from '../shared';


export const usePost = (middleware) => {
    return function(path, payload, config) {
        return  AxiosService.post(path, payload, config)
        .then((response) => response.data)
        .catch(middleware)   
    }
}

export const useGet = (middleware) => {
    return function(path, config)  {
        return AxiosService.get(path, config)
        .then((response) => response.data)
        .catch(middleware)
    }
}


export const usePut = (middleware) => {
    return function(path, payload, config) {
        return AxiosService.put(path, payload, config)
        .then((response) => response.data)
        .catch(middleware)
    }
}


export const useDelete = (middleware) => {
    return function(path, payload, config) {
        return AxiosService.delete(path, payload, config)
        .then((response) => response.data)
        .catch(middleware)
    }
}


export const usePatch = (middleware) => {
    return function(path, payload, config) {
        return AxiosService.path(path, payload, config)
        .then((response) => response.data)
        .catch(middleware)
    }
}


export const get = async(path, config) => {
  return  AxiosService.get(path, config)
}

export const post = async(path, payload, config) => {
  return  AxiosService.post(path, payload, config)
}

export const put = async(path, payload = {}) => {
    return  AxiosService.put(path, payload)
}


export const deleteRequest = async(path, payload = {}) => {
    return  AxiosService.delete(path, payload, config)
}
   

export const patch =  async(path, payload = {}) => {
    return  AxiosService.path(path, payload)
}

export const httpDelete = async(path, config) => {
    return  AxiosService.delete(path, config)
}

