import { Helmet } from 'react-helmet';
import { Box, Container, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useGet } from '../../API/request';
import StatisticsItem from '../../components/dashboard/StatisticsItem';
import { Loader } from '../../components/loader/Loader';


const Dashboard = () => {
  const [loaded, setLoaded] = useState(false);

  const getU = useGet();

  const [statistics, setStatistics] = useState({
    allClientsCount: 0,
    activeClientsCount: 0,
  })

  const fethGetStatistics = async() => {
    setLoaded(true)
    try {
      const allClients = await getU('company/countAll')
  
      if (allClients.status === 'success') {
        setStatistics(prev => ({...prev, allClientsCount: allClients.data.count}))
      }
      
      const activeClients = await getU('company/countActive')
   
      if (activeClients.status === 'success') {
        setStatistics(prev => ({...prev, activeClientsCount: activeClients.data.count}))
      }
  
    } catch (error) {
      console.log(error)
    } finally {
      setLoaded(false)
    }
  }

  useEffect(() => {
    fethGetStatistics()
  }, []);

  if (loaded) {
    return <Loader/>;
  }


  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">Statistics</Box>
      </Box>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item lg={3} sm={6} xl={4} xs={12}>
              <StatisticsItem count={statistics.allClientsCount} title={'Total clients'} />
            </Grid>
            <Grid item lg={3} sm={6} xl={4} xs={12}>
              <StatisticsItem count={statistics.activeClientsCount} title={'Active clients'} />
            </Grid>
            {/* <Grid item lg={3} sm={6} xl={4} xs={12}>
              <StatisticsItem name={data?.date} />
            </Grid>
            <Grid item lg={3} sm={6} xl={4} xs={12}>
              <StatisticsItem name={data?.date} />
            </Grid> */}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
