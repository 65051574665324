import { PayloadAction } from "@reduxjs/toolkit/dist/createAction";
import { ArchiveInitState } from "./types";

export const reducers = {

  setCurrentDayCell(state: ArchiveInitState, action: PayloadAction<{ id: number }>) {
    const { id } = action.payload

    if (state.currentDayCell === id) {
      state.currentDayCell = null;
    } else {
      state.currentDayCell = id
    }
  },

  resetNotification(state: ArchiveInitState) {
    state.error = null;
    state.success = null;
  },

  resetError(state: ArchiveInitState) { //TODO
    state.error = null;
  },

  resetSuccess(state: ArchiveInitState) {//TODO
    state.success = null;
  },
  
};
