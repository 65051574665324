import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@material-ui/core';
import type { ReactNode } from 'react';



type Props = {
  seleckList: string[];
  value: string;
  onChange: ((event: SelectChangeEvent<string>, child: ReactNode) => void) | undefined;
  label: string;
  name: string;
  showReset: boolean;
}

const DropDownSelect = ({ seleckList, value, onChange, label, name, showReset=true }: Props) => {

  if (!seleckList) {
    return null;
  }

  return (
    <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        labelId={label}
        name={name}
        value={value}
        label={label}
        onChange={onChange}
        >


        {seleckList?.map((item, index) => (
          <MenuItem  key={`${item}${index}`} value={item}>{item}</MenuItem>
          ))}
      {value !== '' && showReset &&
          <MenuItem value="">reset</MenuItem>
      }
      

      </Select>
    </FormControl>
  );
};

export { DropDownSelect };
