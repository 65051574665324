import { CookieService } from "../../service";
import { ACCESS_TOKEN_COOKIE, ACCESS_TOKEN_LOCALE_STORAGE, API_URL, REFRESH_TOKEN_LOCALE_STORAGE, TOKEN_RECEIVED_LOCALE_STORAGE, USER_LOCALE_STORAGE } from "../axios/config";

const getToken = () => {
  const tokenLocalStorage = window.localStorage.getItem(
    ACCESS_TOKEN_LOCALE_STORAGE
  );
  const tokenCookie = CookieService.get(ACCESS_TOKEN_COOKIE);
  return tokenLocalStorage || tokenCookie || '';
};

export function fethConfig(method, payload) {
  const payloadIsFormData = payload instanceof FormData

  const config = {
    method: method,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    }
  };

  if (!payloadIsFormData) {
    config.headers["Content-Type"] = 'application/json'
  }

  if (payload) {
    const currentPayload = payload instanceof FormData ? payload : JSON.stringify(payload);
    config.body = currentPayload;
  }

  return config;
}

export function fetchUrl(url, params) {
  let mainUrl = new URL(API_URL);

  if (url) {
    mainUrl = new URL(`${mainUrl}api/${url}`);
  } 
  
  if (params) {
    const paramsString = getParamsString(params)
    const searchParams = new URLSearchParams(paramsString);
    mainUrl.search = searchParams.toString();
  }

  return mainUrl.toString();
}

function getParamsString(params) {
  let resultString = ''
  
  for (const key in params) {
    const value = params[key]
    if (value) {
      resultString += `&${key}=${value}`
    }
  }

  return resultString.slice(1)
}

export const setLocalStorage = (accessToken, refreshToken, user) => {
  window.localStorage.setItem(ACCESS_TOKEN_LOCALE_STORAGE, accessToken);
  window.localStorage.setItem(REFRESH_TOKEN_LOCALE_STORAGE, refreshToken);

  window.localStorage.setItem(USER_LOCALE_STORAGE, JSON.stringify(user));
  window.localStorage.setItem(TOKEN_RECEIVED_LOCALE_STORAGE, Date.now() / 1000);
  CookieService.set(accessToken, ACCESS_TOKEN_LOCALE_STORAGE);
};