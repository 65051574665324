import { FetchService } from "../../../shared/api/fetch/service";
import { CreateDayInput, CreateDayResponse, DownLoadFileInput, GetAllDaysResponse, GetFilesParamsInput, GetFilesResponse, UploadFileInput, UploadFileResponse } from "../DTO/archive-dto";


const getFiles = (params: GetFilesParamsInput): Promise<GetFilesResponse> => {
  const getFilesUrl = 'clients/files';

  return FetchService.get(getFilesUrl, params );
};

const createDay = (payload: CreateDayInput): Promise<CreateDayResponse> => {
  const createDayUrl = 'day/create';

  return FetchService.post({ url: createDayUrl, payload, params: null });
};

const getAllDays = (): Promise<GetAllDaysResponse> => {
  const allDayUrl = 'day/all';
  return FetchService.get( allDayUrl, null );
};

// const removeFiles = (id: string ): Promise<GetAllDaysResponse> => {
//   const removeFileUrl = `files/delete/id`;
//   return FetchService.delete({url: removeFileUrl, payload: null, params: null });
// };

const downloadFile = async(params: DownLoadFileInput): Promise<Blob> => { 
  const { company_id, day_id, name } = params
  const downLoadFileUrl = 'files/download';

  const mainUrl = 'https://docs.backend.usdemowts.ru/'
  const url = `${mainUrl}api/${downLoadFileUrl}?day_id=${day_id}&company_id=${company_id}&name=${name}`;

  const response = await fetch(url);

  return await response.blob();
};

const uploadFile = (payload: UploadFileInput): Promise<UploadFileResponse> => { 
  const uploadFileUrl = 'files/upload';
  const { company_id, day_id, file } = payload

  const formData = new FormData();
  formData.set("file", file);

 
  const paramsUpload = { company_id, day_id }

  return FetchService.post({
    url: uploadFileUrl,
    params: paramsUpload,
    payload: formData,
  });
};


export const ArchiveApi = {
  downloadFile,
  createDay,
  getFiles,
  uploadFile,
  getAllDays,
};
