import { useNavigate } from 'react-router-dom';
// import { Button } from '@material-ui/core';

type Props = {
  item: string | null;
  label: string;
  widthLabel?: number;
  href?: string | null;
};

const InfoFieldItem = (props: Props) => {
  const { item, label, widthLabel = 200, href } = props;
  const navigate = useNavigate()
  
  return (
    <div className="wrap">
      <div className="label" style={{ minWidth: widthLabel }}>
        {label}
      </div>
      {item ? (
        <div className="text">
         <span onClick={() => href ? navigate(`${href}`) : {}} style={href ? {color: 'blue', cursor: 'pointer'} :{}}>{item}</span> 
        </div>
      ) : (
        <div className="text">---</div>
      )}
    </div>
  );
};

export { InfoFieldItem };
